import axios from 'axios';

export const rpc = (
  operationName: string,
  variables: object | null = null,
  apiUrl: string
) => {
  const response = axios({
    method: 'POST',
    url: apiUrl,
    headers:{
      'X-CAL-SECRET-TOKEN': 'D1A6156474841C5D7EAAED9A83A69'
    },
    withCredentials: true,
    data: {
      operation: operationName,
      variables,
    },
  }).then(({ data }) => [data?.data?.[operationName] ?? null, data]);

  return response;
};
