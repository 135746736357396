import { useState } from 'react';
import { rpc } from './l16_api';
import { FaInfoCircle } from 'react-icons/fa';

interface DocuSignButtonProps {
  callbackUrl: string;
  signed: boolean;
  documentLabel: string;
  signedLabel: string;
  notSignedLabel: string;
  isDisabled: boolean;
  // new props for V2
  apiUrl: string;
  customerId: string;
  envelopeId: string;
  brand?: 'common' | 'noah';
}

type LeaseDict = {
  [key: string]: string;
};

const leaseDict: LeaseDict = {
  "Lease Packet": "This is the overall lease for the co-living suite. This lease will be annual and correspond directly to the suites, not individual rooms. Likewise, the monthly rent and security deposit prices listed are for the entire suite. The tenant is only responsible for the allocable portion of monthly rent during their occupancy term, as detailed in the household packet.",
  "Individual Packet": "This will include the applicant’s W-9 or W-8 form. We require a W8/W9 in order to open an escrow account for the prospective member’s security deposit. Though at some homes we don’t require a security deposit, but a move-in fee instead depending on the state requirements. In that case, we need the W8/W9 to add the applicant as an authorized payer within our system.",
  "Household Packet": "This packet is for co-living applicants only! This packet will detail the specifics of a co-living unit and have a section detailing the applicant's portion of the rent/security deposit price. This packet will also list the specific start/end date of the applicant's occupancy term at Common.",
  "Lease Amendment Packet": "This is a single document stating the applicant will be taking over the current co-living suite lease from the vacating tenant.",
}

// V2 Logic
//  Step 1. On Click, call L16 to get the Docusign signing link
//  Step 2. Do the redirect (following prior patterns used) to Docusign site with an (optional) callback URL
export const DocuSignButtonV2 = ({
  callbackUrl,
  signed,
  documentLabel,
  signedLabel,
  notSignedLabel,
  isDisabled,
  // new props for V2
  apiUrl,
  customerId,
  envelopeId,
  brand = 'common',
  ...props
}: DocuSignButtonProps) => {
  const [disabled, setDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // TODO: react-query
  const fetchSigningLink = async () => {
    const response = await rpc(
      'leasing/get_lease_packet_signing_links',
      {
        external_signer_id: customerId,
        docusign_envelope_id: envelopeId,
        redirect_url: callbackUrl,
      },
      apiUrl
    );
    const url = response[0][0];
    window.location = url;
  };

  return (
    <div className='flex gap-10 items-center justify-between'>
      <div className='flex items-center gap-3'>
        <h3>{documentLabel}</h3>
        <div className="tooltip" data-tip={leaseDict[documentLabel]}>
          <FaInfoCircle />
        </div>
      </div>

      <button
        className={`
        ${brand === 'common' ? 'bg-common-blue' : 'bg-noah-green'}
        hover:bg-opacity-80 text-white font-bold py-2 px-8 rounded disabled:opacity-70`}
        onClick={() => {
          // Disable to prevent double opening
          setIsLoading(true);
          setDisabled(true);
          fetchSigningLink().catch(console.error);
        }}
        // primary
        {...props}
        disabled={isDisabled || disabled}
        // loading={isDisabled && disabled}
      >
        {signed ? signedLabel : notSignedLabel}
        {isLoading && <span className="loading loading-spinner"></span>}
      </button>
    </div>
  );
};
