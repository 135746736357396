import {
  QueryClient,
  QueryClientProvider,
  useQuery,
} from '@tanstack/react-query';
import { rpc } from './l16_api';
import { DocuSignButtonV2 } from './DocusignButton';

const colivingDesc = "Please note that co-living lease for the suite are set by the dates of the first member to move into the suite. If there are multiple lease and household packets, then the applicant's occupancy term spans across multiple leases for the co-living suite lease.\n The  Example: Jane Doe term is from 11/2/2021 to 11/1/2022 she is moving into a co-living suite and the lease for the suite run from 09/1/2021 to 08/31/2022. Jane Doe will have to sign two lease packets and two household packets. She will be on the first lease from 11/2/2021 (her start date) until 08/31/2022 (lease end date for the suite) and then to cover the remaining portion of her occupancy term she will then sign a new lease for the suite (09/01/2022) until 11/1/2022 (her occupancy end date)."

const queryClient = new QueryClient();

function App() {
  const apiUrl =
    process.env.REACT_APP_LOUIS_16_URL || 'https://louis16.common.com/';
  console.log('apiUrl', apiUrl);

  const brand = window.location.hostname.includes('noah') ? 'noah' : 'common';
  const title = brand === 'common' ? 'Common' : 'Noah';

  const path = window.location.pathname;
  const matchDigits = /^\d+$/;
  const pathParts = path.split('/').filter((p) => p.match(matchDigits));
  const leaseId = pathParts[0];
  const intervalId = pathParts[1];
  const customerId = pathParts[2];
  console.log('IDs', leaseId, intervalId, customerId);

  const { isLoading, error, data } = useQuery({
    queryKey: ['leases', leaseId, intervalId, customerId],
    queryFn: async () => {
      console.log('fetching lease documents');
      const response = await rpc(
        'member_onboarding/get_coliving_lease_documents',
        {
          lease_id: leaseId,
          interval_id: intervalId,
          customer_id: customerId,
          brand,
        },
        apiUrl
      );
      console.log('response', response);

      return response[0];
    },
    enabled: !!(leaseId && intervalId && customerId),
    retry: 0,
  });
  console.log(`data`, data);

  const l16Error = data?.errors && data?.errors[0];

  return (
    <div className='bg-white min-h-screen'>
      <nav>
        <div
          className={`navbar ${
            brand === 'common' ? 'bg-common-blue' : 'bg-noah-green'
          }`}
        >
          <span className='btn btn-ghost normal-case text-xl text-white'>
            {title}
          </span>
        </div>
      </nav>

      <main className='p-10 grid grid-cols-1 place-items-center'>
        <hgroup className='py-4 flex flex-col items-center gap-2'>
          <h1 className='text-3xl tracking-wider'>Sign your lease</h1>
          <div className="tooltip tooltip-bottom text-xl" data-tip={colivingDesc}>
            <button className="btn btn-sm">Learn more</button>
          </div>
        </hgroup>

        {/* Loading */}
        {isLoading ? (
          <div className='text-center'>
            <span
              className={`loading loading-spinner loading-lg 
          ${brand === 'common' ? 'text-common-blue' : 'text-noah-green'}
          `}
            ></span>
            <br />
            Loading your lease documents...
          </div>
        ) : null}

        {/* Axios Errors */}
        {!!error && <div>Error: {JSON.stringify(error)}</div>}

        {/* L16 Errors */}
        {!!l16Error && (
          <div className='text-red-500 italic text-center'>
            Error: {JSON.stringify(l16Error)}
          </div>
        )}

        <div className='mt-4' />

        {!l16Error && <ul className='space-y-4'>
          {data?.map((item: any) => {
            return (
              <DocuSignButtonV2
                key={item}
                apiUrl={item.apiUrl}
                callbackUrl={item.callbackUrl}
                signed={item.signed}
                documentLabel={item.documentLabel}
                signedLabel={item.signedLabel}
                notSignedLabel={item.notSignedLabel}
                isDisabled={item.signed}
                customerId={customerId}
                envelopeId={item.envelopeId}
              />
            );
          })}
        </ul>}
      </main>
    </div>
  );
}

export default function WrappedApp() {
  return (
    <QueryClientProvider client={queryClient}>
      <App />
    </QueryClientProvider>
  );
}
